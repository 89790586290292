import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gitversion"
    }}>{`GitVersion`}</h1>
    <p>{`Versioning is annoying and hard, but luckily for us, the process can be mostly automated. To do this, we use a tool called `}<a parentName="p" {...{
        "href": "https://github.com/GitTools/GitVersion"
      }}>{`GitVersion`}</a>{`. This tool will auto-calculate version numbers based on commits / history / tags and will generate proper versions when working on different branches. Versioning is based on `}<a parentName="p" {...{
        "href": "https://semver.org/"
      }}>{`semantic versioning`}</a>{`. Please note that GitVersion is NOT about versioning our code, it is about versioning our artifacts. Someone or something (a person or CI/CD tooling) would still needs to tag the repository with a version number to link code & artifact.`}</p>
    <h2 {...{
      "id": "installing-gitversion"
    }}>{`Installing GitVersion`}</h2>
    <p>{`You can download the latest version from github: `}<a parentName="p" {...{
        "href": "https://github.com/GitTools/GitVersion/releases"
      }}>{`https://github.com/GitTools/GitVersion/releases`}</a>{`. Give the executable a place and make it available inside your `}<inlineCode parentName="p">{`PATH`}</inlineCode>{` or `}<inlineCode parentName="p">{`/usr/bin`}</inlineCode>{`. This way, you can use it anywhere.`}</p>
    <h2 {...{
      "id": "using-and-configuring-gitversion"
    }}>{`Using and configuring GitVersion`}</h2>
    <p>{`We mainly use trunk based development, which in terms of GitVersion is called `}<a parentName="p" {...{
        "href": "https://gitversion.net/docs/reference/versioning-modes/mainline-development"
      }}>{`GitHubFlow or mainline development`}</a>{`. GitVersion can be configured using a GitVersion.yml which will be automatically generated if you use `}<inlineCode parentName="p">{`GitVersion init`}</inlineCode>{` inside a repository. With the GitVersion.yml, you can completely `}<a parentName="p" {...{
        "href": "https://gitversion.net/docs/configuration"
      }}>{`adjust GitVersion`}</a>{` to your needs. As a minimum, we always use the following configuration inside the GitVersion.yml:`}</p>
    <pre><code parentName="pre" {...{}}>{`mode: Mainline
branches:
  master:   
    tag: ''
`}</code></pre>
    <p>{`Once this is done, and your repository has a commit, you can run `}<inlineCode parentName="p">{`GitVersion`}</inlineCode>{` from your console and it will spit out alot of information. The most important one is the `}<inlineCode parentName="p">{`SemVer`}</inlineCode>{` property.`}</p>
    <h2 {...{
      "id": "increment-majorminor"
    }}>{`Increment major/minor`}</h2>
    <p>{`By default, GitVersion will bump patch numbers. If you need to adjust major or minor numbers, you can `}<a parentName="p" {...{
        "href": "https://gitversion.net/docs/more-info/version-increments"
      }}>{`use one of the following approaches`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      